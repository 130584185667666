import { usersService } from '@/_services'

export const authentication = {
  namespaced: true,
  state: {
    user: undefined,
    username: '',
    password: ''
  },
  mutations: {
    setUser (state, input) {
      // console.log('setUser', input)
      localStorage.setItem('user', JSON.stringify(input))
      state.user = input
    }
  },
  actions: {
    updateTokenIfNeeded ({ state, commit, dispatch }, payload) {
      // console.log('updateTokenIfNeeded')
      if (state.user !== undefined && state.user.token !== undefined && state.general.uimode) {
        let now = new Date()
        // console.log('expire', expire)
        const expire = new Date(state.user.token.tokenexpire)
        // console.log('now', now)
        // console.log('expire', expire)
        // console.log('expire minutes:', Math.round((expire - now) / 1000 / 60))
        now = new Date(now.getTime() + (15 * 60000))
        // console.log('now+15', now)
        if (now > expire) {
          usersService.updateToken().then((response) => {
            // var expires = ''// ';expires=' + response.data.token.expireUtcString
            // document.cookie = 'user=' + response.data.email + expires + ';path=/'
            // document.cookie = 'token=' + response.data.token.tokenstring + expires + ';path=/'
            // document.cookie = 'tokenexpireutc=' + response.data.token.expireUtcString + expires + ';path=/'
            commit('setUser', response.data)
            // context.commit('setAuthHeader', 'Bearer ' + response.data.token.tokenstring)
          }).catch((error) => {
            console.log('Error after updateToken()', error)
            dispatch('resetCookieAndUser', true)
          })
        }
      }
    },
    resetCookieAndUser ({ commit }, raiseManualError) {
      // console.log('resetCookieAndUser, raiseManualError:', raiseManualError)
      commit('setUser', undefined)
      localStorage.removeItem('user')

      if (raiseManualError === true) {
        commit('general/setErrorMessage', { type: 'warning', header: 'Uitgelogd', message: 'U bent automatisch uitgelogd omdat u langere tijd niet actief bent geweest', manual: true }, { root: true })
      }
    }
  }
}
