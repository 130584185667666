export default function stripTrailingZeros (value) {
  if (value === undefined || value == null) {
    return ''
  } else {
    value = value.toString()
    if (value.endsWith('.00')) {
      return value.substring(0, value.length - 3)
    } else {
      return value
    }
  }
}
