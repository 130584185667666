<template>
  <v-card :flat="noborder" v-if="fieldsettings.length > 0">
    <v-btn icon v-if="allowedit && compfieldsettings.find(x => x.allowWebEdit === true)" @click="initEditData()" style="position:absolute;z-index:5;right:5px;margin-top:-10px;">
      <v-icon>edit</v-icon>
    </v-btn>
    <template v-if="title || dialog">
      <v-card-title>{{dialog ? 'Wijzigingsvoorstel' : title}}</v-card-title>
      <v-divider />
    </template>
    <v-card-text class="pa-5 ma-2">
      <v-row v-for="field in compfieldsettings" :key="field.name">
        <template v-if="(['varchar','int','decimal','date','datetime']).includes(field.fieldType)">
          <v-text-field
            :readonly="readonly || field.primKey"
            :disabled="field.primKey"
            dense
            background-color="grey lighten-5"
            :counter="dialog && field.maxLength !== 0 ? field.maxLength : false"
            :maxlength="dialog && field.maxLength !== 0 ? field.maxLength : false"
            :error="dialog && newtabledata[field.name] !== undefined && tabledata[field.name] !== newtabledata[field.name]"
            :prefix="(dialog && !field.primKey && tabledata[field.name] !== undefined) ? (field.displayname + ' \x22' + (tabledata[field.name] === ' ' ? '' : tabledata[field.name]) + '\x22\xa0\xa0wijzigen in:\xa0\xa0' ) : ''"
            :label="dialog ? '' : field.displayname"
            v-model="newtabledata[field.name]"
          >
            <!-- <template v-slot:label>
              <span class="primary--text font-weight-light">{{field.displayname}}</span>
            </template> -->
          </v-text-field>
        </template>
        <template v-else-if="field.fieldType === 'bit'">
          <template v-if="dialog && !field.primKey && tabledata[field.name] !== undefined">
            {{ '\x22' + $options.filters.formatBool(tabledata[field.name]) + '\x22\xa0\xa0wijzigen in:\xa0\xa0' }}
          </template>
          <v-checkbox
            :readonly="readonly || field.primKey"
            :disabled="field.primKey"
            dense
            hide-details
            style="margin-top:-10px;margin-bottom:15px;"
            :label="field.displayname"
            v-model="newtabledata[field.name]"
          ></v-checkbox>
        </template>
        <template v-else>
          {{field.displayname}}: {{newtabledata[field.name]}}
        </template>
      </v-row>
    </v-card-text>
    <v-card-actions v-if="dialog">
      <v-spacer />
      <v-btn @click="cancel">Annuleer</v-btn>
      <v-btn @click="send">Verstuur</v-btn>
    </v-card-actions>
    <v-dialog v-model="editDialog" fullscreen>
      <app-DisplayData :tablename="tablename" :fieldsettings="fieldsettings" :tabledata="newtabledata" dialog @cancel="editDialog=false" style="padding:10px;" />
    </v-dialog>
  </v-card>
</template>

<script>
import { globalService } from '../_services'

export default {
  name: 'DisplayData',
  props: {
    title: String,
    tablename: String,
    fieldsettings: {
      type: Array,
      default: function () {
        return [{ name: 'id', displayname: 'ID', fieldType: 'int', maxLength: 0, showOnWeb: false, allowWebEdit: false, primKey: true }]
      }
    },
    tabledata: [Object, String],
    readonly: Boolean,
    allowedit: Boolean,
    noborder: Boolean,
    dialog: Boolean
  },
  data () {
    return {
      editDialog: false,
      newtabledata: {}
    }
  },
  watch: {
    tabledata: {
      handler: function (newValue) {
        this.initNewTabledData(newValue)
      },
      deep: true
    },
    fieldsettings: {
      handler: function (newValue) {
        this.initNewTabledData(this.tabledata)
      },
      deep: true
    }
  },
  created () {
    // console.log('created')
    if (!this.dialog) {
      this.initNewTabledData(this.tabledata)
    }
  },
  computed: {
    showOnWebFields () {
      return this.fieldsettings.filter(function (e, index) { return e.showOnWeb === true })
    },
    showOnWebFieldsWithPrimKey () {
      return this.fieldsettings.filter(function (e, index) { return e.showOnWeb === true || e.primKey === true })
    },
    compfieldsettings () {
      return this.dialog ? this.showOnWebFields.filter(function (e, index) { return e.allowWebEdit === true }) : this.showOnWebFields
    },
    compfieldsettingsWithPrimKey () {
      return this.dialog ? this.showOnWebFields.filter(function (e, index) { return e.allowWebEdit === true || e.primKey === true }) : this.showOnWebFieldsWithPrimKey
    }
  },
  methods: {
    initNewTabledData: function (input) {
      // console.log('initNewTabledData, input:', input)
      if (input !== undefined && input !== null && input !== '' && Object.keys(input).length > 0) {
        this.compfieldsettingsWithPrimKey.forEach(field => {
          if (field.fieldType === 'date') {
            this.newtabledata[field.name] = input[field.name] === '' || input[field.name] === undefined ? ' ' : this.$options.filters.formatDate(input[field.name])
          } else if (field.fieldType === 'datetime') {
            this.newtabledata[field.name] = input[field.name] === '' || input[field.name] === undefined ? ' ' : this.$options.filters.formatDateTime(input[field.name])
          } else {
            this.newtabledata[field.name] = input[field.name] === '' || input[field.name] === undefined ? ' ' : input[field.name]
          }
        })
        // this.dialog = !this.dialog
        // this.dialog = !this.dialog
      }
    },
    initEditData: function () {
      // this.newtabledata = Object.assign({}, this.tabledata)
      this.editDialog = true
    },
    cancel: function () {
      this.$emit('cancel')
    },
    send: function () {
      let body = ''
      const vm = this
      // List Primary keys
      this.fieldsettings.filter(function (e, index) { return e.primKey === true }).forEach(field => {
        body += field.displayname + '=' + vm.tabledata[field.name] + '\n'
      })
      body += '\n'
      // List changed fields
      for (const propertyName in this.newtabledata) {
        body += this.fieldsettings.find(obj => { return obj.name === propertyName }).displayname + '\xa0=>\xa0' + this.newtabledata[propertyName] + '\n'
      }
      // Send email
      globalService.sendmail(this.$store.state.general.adminEmail, this.$store.state.authentication.user.email, 'Wijzigingsvoorstel voor ' + this.tablename, body).then((response) => {
      })

      this.$emit('cancel')
    }
  }
}
</script>
