<template>
  <v-app>
    <template v-if="uimode">
      <v-navigation-drawer
        fixed
        temporary
        v-model="showSideNav"
        app
      >
        <v-list dense>
          <v-list-item
            router
            to="/">
            <v-list-item-action>
              <v-icon>home</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="item in filteredMenuItems"
            :key="item.title"
            router
            :to="item.link">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
                </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-app-bar
        color="primary"
        dark
        :fixed="true"
      >
        <v-app-bar-nav-icon
          @click.native.stop="showSideNav = ! showSideNav"
          class="hidden-sm-and-up">
          <v-icon>menu</v-icon>
        </v-app-bar-nav-icon>
        <!-- <v-app-bar-nav-icon
          @click.native.stop="showSideNav = ! showSideNav"
          class="hidden-sm-and-up"></v-app-bar-nav-icon> -->
        <router-link to="/" tag="span" style="cursor: pointer">
          <v-toolbar-title>{{ naamKerk }}</v-toolbar-title>
        </router-link>
        <v-spacer></v-spacer>
        <v-toolbar-items
          class="hidden-xs-only"
          v-for="item in filteredMenuItems"
          :key="item.title"
          >
          <template>
            <v-btn
              class="hidden-sm-and-down"
              small
              text
              router
              :to="item.link">
              <v-icon left>{{ item.icon }}</v-icon>
              {{ item.title }}
            </v-btn>
            <v-btn
              class="hidden-md-and-up"
              text
              icon
              :to="item.link">
              <v-icon>{{ item.icon }}</v-icon>
            </v-btn>
          </template>
        </v-toolbar-items>
      </v-app-bar>
      <div style="height:64px;"></div>
    </template>
    <v-container fluid v-if="errorMessage != null" class="pa-3">
      <v-alert
        :type="errorMessage == null ? 'error' : errorMessage.type"
        transition="scale-transition"
      >
        <h2>{{ errorMessage == null ? '' : errorMessage.header}}</h2>
        <br />
        <app-MultiLine :inputText="errorMessage == null ? '' : errorMessage.message" />
        <br />
        <center>
          <v-btn @click.stop="$store.commit('general/setErrorMessage', null)">Ok</v-btn>
        </center>
      </v-alert>
    </v-container>
    <v-container fluid>
      <router-view />
    </v-container>
  </v-app>
</template>

<script>
import { usersService } from '@/_services'
export default {
  name: 'App',
  data () {
    return {
      // timer: '',
      showSideNav: false,
      menuItems: [
        { icon: 'contacts', title: 'Contacten', link: '/Contacts', showAuthOnly: false, rights: '' },
        { icon: 'build', title: 'Admin', link: '/Admin', showAuthOnly: true, rights: 'admin' },
        { icon: 'account_circle', title: 'Account', link: '/User/User', showAuthOnly: true, rights: 'allusers' },
        { icon: 'lock', title: 'Afmelden', link: '/User/Signout', showAuthOnly: true, rights: 'allusers' },
        { icon: 'lock_open', title: 'Inloggen', link: '/User/Signin', showAuthOnly: false, rights: 'allusers' }
      ]
    }
  },
  created () {
    this.getSettings()
    const user = JSON.parse(localStorage.getItem('user'))
    // console.log('localstorage user', user)
    if (user !== null && this.uimode) {
      this.$store.commit('authentication/setUser', user)
      usersService.checkToken().then((response) => {
        if (response.data.email !== undefined) {
          // console.log('response.data', response.data)
          console.log('Valid token found in cookie for user:', response.data.email, 'New token valid until (UTC):', response.data.token.tokenexpire)
          this.$store.commit('authentication/setUser', response.data)
        } else {
          console.log('Token found in cookie but it\'s not valid')
          this.$store.dispatch('authentication/resetCookieAndUser', true)
        }
      })
    } else {
      this.$store.dispatch('authentication/resetCookieAndUser', false)
    }
  },
  // updated () {
  //   console.log('updated')
  //   this.$store.dispatch('authentication/updateTokenIfNeeded')
  // },
  computed: {
    filteredMenuItems () {
      const authenticated = this.$store.state.authentication.user !== undefined
      const rights = authenticated ? this.$store.state.authentication.user.rights : ''
      return this.menuItems.filter(function (e) { return (!e.showAuthOnly ? (!authenticated || e.rights === '') : (authenticated && rights.includes(e.rights))) })
    },
    uimode () {
      return this.$store.state.general.uimode
    },
    errorMessage () {
      return this.$store.state.general.errorMessage
    },
    naamKerk () {
      return this.$store.state.general.settings.NaamKerk
    }
  },
  methods: {
    getSettings: function () {
      // console.log('getSettings')
    //   globalService.getAllSettings().then((response) => {
    //     var setting = []
    //     response.data.forEach(function (entry) {
    //       // console.log('entry', entry)
    //       if (entry.value != null) {
    //         // console.log('adding value', entry.value)
    //         if (entry.value.startsWith('{')) {
    //           setting[entry.key] = entry.value
    //         } else if (entry.value.includes(',')) {
    //           setting[entry.key] = entry.value.split(',')
    //         } else {
    //           setting[entry.key] = entry.value
    //         }
    //       }
    //     })
    //     this.$store.commit('general/setSettings', setting)
    //   })
    }
  }
}
</script>

<style>
.lsdatatable table tbody tr:hover {
  background-color: rgba(25, 118, 210, 0.3)!important;
}
.lsdatatable table tbody tr:nth-child(even) {
  background-color: rgba(25, 118, 210, 0.1);
}
.lsdatatable table th {
  font-weight: bold;
  font-size: 1em;
  background-color: rgba(25, 118, 210, 0.1);
}
</style>
