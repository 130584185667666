import axios from 'axios'
import store from '../store'
import { authHeader } from '../_helpers'

const RESOURCE_NAME = '/Contact'
axios.defaults.baseURL = store.state.general.webServiceAddress
// axios.defaults.headers['Content-Type'] = 'application/json'

export const contactService = {
  getMyContact,
  getContactById,
  getMyContactTabData,
  getContactTabById,
  getMyAddresses,
  getAddressesById,
  getMyCommunication,
  getCommunicationById
}

function getMyContact () {
  return axios.get(RESOURCE_NAME, { headers: authHeader() }).catch((error) => {
    store.dispatch('general/raiseError', error)
    return Promise.reject(error)
  })
}

function getContactById (id) {
  return axios.get(`${RESOURCE_NAME}/${id}`, { headers: authHeader() }).catch((error) => {
    store.dispatch('general/raiseError', error)
    return Promise.reject(error)
  })
}

function getMyContactTabData (tabid) {
  return axios.get(`${RESOURCE_NAME}/ContactTab/${tabid}`, { headers: authHeader() }).catch((error) => {
    store.dispatch('general/raiseError', error)
    return Promise.reject(error)
  })
}

function getContactTabById (tabid, id) {
  return axios.get(`${RESOURCE_NAME}/ContactTab/${tabid}/${id}`, { headers: authHeader() }).catch((error) => {
    store.dispatch('general/raiseError', error)
    return Promise.reject(error)
  })
}

function getMyAddresses () {
  return axios.get(`${RESOURCE_NAME}/Address`, { headers: authHeader() }).catch((error) => {
    store.dispatch('general/raiseError', error)
    return Promise.reject(error)
  })
}

function getAddressesById (id) {
  return axios.get(`${RESOURCE_NAME}/Address/${id}`, { headers: authHeader() }).catch((error) => {
    store.dispatch('general/raiseError', error)
    return Promise.reject(error)
  })
}

function getMyCommunication () {
  return axios.get(`${RESOURCE_NAME}/Communication`, { headers: authHeader() }).catch((error) => {
    store.dispatch('general/raiseError', error)
    return Promise.reject(error)
  })
}

function getCommunicationById (id) {
  return axios.get(`${RESOURCE_NAME}/Communication/${id}`, { headers: authHeader() }).catch((error) => {
    store.dispatch('general/raiseError', error)
    return Promise.reject(error)
  })
}
