export default function formatDateWithDay (value) {
  if (value === undefined || value == null) { return '' }
  let returnVal = ''
  if (value !== null) {
    let date = Date
    date = new Date(value)
    const dayInt = date.getDay()
    let day
    if (dayInt === 0) {
      day = 'zo'
    } else if (dayInt === 1) {
      day = 'ma'
    } else if (dayInt === 2) {
      day = 'di'
    } else if (dayInt === 3) {
      day = 'wo'
    } else if (dayInt === 4) {
      day = 'do'
    } else if (dayInt === 5) {
      day = 'vr'
    } else if (dayInt === 6) {
      day = 'za'
    }
    returnVal = day + ' ' + date.getDate().toString() + '-' + (date.getMonth() + 1).toString() + '-' + date.getFullYear().toString()
  }
  return returnVal
}
