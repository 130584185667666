export default function formatTime (value) {
  if (value === undefined || value == null) { return '' }
  let returnVal = ''
  if (value !== null) {
    let date = Date
    date = new Date('2000/01/01 ' + value)
    returnVal = date.getHours().toString() + ':' + (date.getMinutes().toString().length === 1 ? '0' : '') + date.getMinutes().toString()
  }
  return returnVal
}
