export const contact = {
  namespaced: true,
  state: {
    contactFields: [],
    contact: {},
    addressFields: [],
    addresses: [],
    communicationFields: [],
    communication: {},
    cijfers: undefined
  },
  mutations: {
    setContactFields (state, input) {
      state.contactFields = input
    },
    setContact (state, input) {
      state.contact = input
    },
    setAddressFields (state, input) {
      state.addressFields = input
    },
    setAddresses (state, input) {
      state.addresses = input
    },
    setCommunicationFields (state, input) {
      state.communicationFields = input
    },
    setCommunication (state, input) {
      state.communication = input
    },
    setCijfers (state, input) {
      state.cijfers = input
    }
  },
  actions: {
  },
  getters: {
    getContact: state => {
      return state.contact
    }
  }
}
