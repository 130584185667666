<template>
  <v-container fluid>
    <v-card>
      <v-tabs @change="tabChanged()" v-model="selectedTab ">
        <v-tab>Persoonsgegevens</v-tab>
        <v-tab>Adresgegevens</v-tab>
        <v-tab>Contactgegevens</v-tab>
        <v-tab v-for="curContactTab in contactTabs" :key="curContactTab.id">
          {{curContactTab.name}}
        </v-tab>
        <v-tab>Cijfers</v-tab>
      </v-tabs>
      <v-divider />
      <v-card-text>
        <v-tabs-items v-model="selectedTab ">
          <v-tab-item>
            <app-DisplayData tablename="Contact details" :fieldsettings="contactFields" :tabledata="contact" noborder readonly allowedit/>
          </v-tab-item>
          <v-tab-item v-for="curContactTab in contactTabs" :key="curContactTab.id">
            <app-DisplayData :tablename="curContactTab.name" :fieldsettings="curContactTab.fields" :tabledata="curContactTab.data" noborder readonly allowedit/>
          </v-tab-item>
          <v-tab-item>
            <template v-if="addressFields.length > 0">
              <template v-for="address in addresses">
                <app-DisplayData tablename="Adres" :fieldsettings="addressFields" :tabledata="address" :noborder="addresses.length > 1 ? false : true" :title="getAddressTitle(address)" readonly allowedit :key="address.id" />
              </template>
            </template>
          </v-tab-item>
          <v-tab-item>
            <app-DisplayData tablename="Communicatie details" :fieldsettings="communicationFields" :tabledata="communication" noborder readonly allowedit/>
          </v-tab-item>
          <v-tab-item>
            <cijfercomp ref="cijferComponent"></cijfercomp>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { globalService, contactService } from '../_services'
import cijfercomp from './Cijfer.vue'

export default {
  name: 'Contact',
  components: {
    cijfercomp
  },
  data () {
    return {
      selectedTab: undefined,
      initialized: false,
      contactTabs: [],
      localContactTabFields: [],
      localContactTabData: []
    }
  },
  watch: {
    contactTab (to, from) {
      // console.log('watch contactTab, from', from)
      // console.log('watch contactTab, to', to)
    },
    localContactTabFields: {
      handler (to, from) {
        // console.log('watch localContactTabFields, from', from)
        // console.log('watch localContactTabFields, to', to)
      },
      deep: true
    },
    localContactTabData: {
      handler (to, from) {
        // console.log('watch localContactTabData, from', from)
        // console.log('watch localContactTabData, to', to)
      },
      deep: true
    }
  },
  created () {
    // console.log('created')
    this.getContactTabs()
  },
  mounted () {
    // console.log('mounted')
    this.initialized = true
    // console.log('initialized')
    if (this.$route.params.selectedtab === undefined) {
      // this.selectedTab = 0
      this.tabChanged()
    } else {
      const selTab = Number(this.$route.params.selectedtab)
      if (selTab <= 3) {
        this.selectedTab = selTab
        this.tabChanged()
      } else {
        const vm = this
        setTimeout(function () {
          vm.selectedTab = selTab
          vm.tabChanged()
        }, 100)
      }
    }
    // console.log('this.$route.params.selectedtab', this.$route.params.selectedtab)
  },
  computed: {
    // formTitle () {
    //   return this.editedIndex === -1 ? 'Nieuwe Dienst' : (this.itemDialogDelAction ? 'Verwijder Dienst?' : 'Bewerk Dienst')
    // },
    isAdmin () {
      return (this.$store.state.authentication.user === undefined ? false : (this.$store.state.authentication.user.rights.includes('admin')))
    },
    contactFields () {
      return this.$store.state.contact.contactFields
    },
    contact () {
      return this.$store.state.contact.contact
    },
    addressFields () {
      return this.$store.state.contact.addressFields
    },
    addresses () {
      return this.$store.state.contact.addresses
    },
    communicationFields () {
      return this.$store.state.contact.communicationFields
    },
    communication () {
      return this.$store.state.contact.communication
    }
  },
  methods: {
    tabChanged () {
      if (this.initialized) {
        // console.log('tabChanged, selectedTab', this.selectedTab)
        if (this.selectedTab === 0) this.getMyContact()
        else if (this.selectedTab === 1) this.getMyAddresses()
        else if (this.selectedTab === 2) this.getMyCommunication()
        // else if (this.selectedTab === 3) this.initCijfer()
        else if (this.selectedTab > 3) this.getMyContactTabData(this.selectedTab - 4)
      }
    },
    getContactTabs () {
      // console.log('getContactTabs')
      if (this.contactTabs.length === 0) {
        globalService.getContactTabs().then((response) => {
          Object.keys(response.data).forEach(tabid => {
            const name = response.data[tabid]
            this.contactTabs.push({ id: Number(tabid), name: name, data: undefined, fields: undefined })
          })
        })
      }
    },
    getContactFields () {
      // console.log('getContactFields')
      if (this.contactFields.length === 0) {
        globalService.getContactFields().then((response) => {
          this.$store.commit('contact/setContactFields', response.data)
        })
      }
    },
    getMyContact () {
      // console.log('getMyContact')
      if (Object.keys(this.contact).length === 0) {
        contactService.getMyContact().then((response) => {
          this.$store.commit('contact/setContact', response.data)
          this.getContactFields()
        })
      }
    },
    getContactTabFields (tabid) {
      // console.log('getContactTabFields', tabid)
      if (this.contactTabs.find(x => x.id === Number(tabid)) === undefined || this.contactTabs.find(x => x.id === Number(tabid)).fields === undefined) {
        globalService.getContactTabFields(tabid).then((response) => {
          this.contactTabs.find(x => x.id === Number(tabid)).fields = response.data
        })
      }
    },
    getMyContactTabData (tabid) {
      // console.log('getMyContactTabData', tabid)
      if (this.contactTabs.find(x => x.id === Number(tabid)) === undefined || this.contactTabs.find(x => x.id === Number(tabid)).data === undefined) {
        contactService.getMyContactTabData(tabid).then((response) => {
          this.contactTabs.find(x => x.id === Number(tabid)).data = response.data
          this.getContactTabFields(tabid)
        })
      }
    },
    getAddressFields () {
      // console.log('getAddressFields')
      if (this.addressFields.length === 0) {
        globalService.getAddressFields().then((response) => {
          this.$store.commit('contact/setAddressFields', response.data)
        })
      }
    },
    getMyAddresses () {
      // console.log('getMyAddresses')
      if (Object.keys(this.addresses).length === 0) {
        contactService.getMyAddresses().then((response) => {
          this.$store.commit('contact/setAddresses', response.data)
          this.getAddressFields()
        })
      }
    },
    getCommunicationFields () {
      // console.log('getCommunicationFields')
      if (this.communicationFields.length === 0) {
        globalService.getCommunicationFields().then((response) => {
          this.$store.commit('contact/setCommunicationFields', response.data)
        })
      }
    },
    getMyCommunication () {
      // console.log('getMyCommunication')
      if (Object.keys(this.communication).length === 0) {
        contactService.getMyCommunication().then((response) => {
          this.$store.commit('contact/setCommunication', response.data[0])
          this.getCommunicationFields()
        })
      }
    },
    getAddressTitle (address) {
      if (this.addresses.length > 1) {
        if (address.isVisitingAddress) {
          return 'Bezoekadres'
        } else {
          return 'Postadres'
        }
      } else {
        return ''
      }
    }
  }
}
</script>
