<template>
  <div v-if="!disabled" style="display:inline">
    <router-link :to="to">
      <slot></slot>
    </router-link>
  </div>
  <div v-else style="display:inline">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    to: String,
    disabled: Boolean
  }
}
</script>
