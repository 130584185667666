import Vue from 'vue'
import Vuex from 'vuex'

import { contact } from './contact.module'
import { authentication } from './authentication.module'
import { general } from './general.module'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    general,
    contact,
    authentication
  }
})
