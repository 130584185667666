import store from '../store'

export function authHeader () {
  // return authorization header with jwt token
  if (store.state.authentication.user !== undefined && store.state.authentication.user.token !== undefined) {
    return { Authorization: 'Bearer ' + store.state.authentication.user.token.tokenstring }
  } else {
    return {}
  }
}
