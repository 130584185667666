import axios from 'axios'
import store from '../store'
import { authHeader } from '../_helpers'

const RESOURCE_NAME = '/Users'
axios.defaults.baseURL = store.state.general.webServiceAddress
axios.defaults.headers['Content-Type'] = 'application/json'

export const usersService = {
  checkToken,
  updateToken,
  login,
  changePassword,
  getTokenByHash
}

function checkToken () {
  return axios.get(`${RESOURCE_NAME}/CheckToken`, { headers: authHeader() }).catch((error) => {
    store.dispatch('general/raiseError', error)
    return Promise.reject(error)
  })
}

function updateToken () {
  console.log('updateToken')
  return axios.get(`${RESOURCE_NAME}/UpdateToken`, { headers: authHeader() }).catch((error) => {
    // store.dispatch('general/raiseError', error)
    return Promise.reject(error)
  })
}

function login (username, password) {
  return axios.post(`${RESOURCE_NAME}/authenticate`, { username: username, password: password }).catch((error) => {
    store.dispatch('general/raiseError', error)
    return Promise.reject(error)
  })
}

function changePassword (username, password, newpassword) {
  return axios.post(`${RESOURCE_NAME}/changepassword`, { username: username, password: password, newpassword: newpassword }).catch((error) => {
    store.dispatch('general/raiseError', error)
    return Promise.reject(error)
  })
}

function getTokenByHash (username, hash) {
  return axios.post(`${RESOURCE_NAME}/GetTokenByHash`, { username: username, hash: hash }).catch((error) => {
    // store.dispatch('general/raiseError', error)
    return Promise.reject(error)
  })
}
