import filters from './filters/'
import Vue from 'vue'
import App from './App.vue'
import { router } from '@/_helpers'
import store from './store'
// import Vuetify from 'vuetify'
// import 'vuetify/dist/vuetify.min.css'
import vuetify from './plugins/vuetify'
import DisplayData from './components/DisplayData'
import MultiLine from './components/MultiLine'
import RouterLink from './components/RouterLink'

Vue.config.productionTip = false

Vue.component('app-DisplayData', DisplayData)
Vue.component('app-MultiLine', MultiLine)
Vue.component('app-router-link', RouterLink)

Vue.use(filters)
// Vue.use(Vuetify)
Vue.use(vuetify)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
