import Vue from 'vue'
import store from '../store/index.js'
import VueRouter from 'vue-router'
import { usersService } from '@/_services'

import Home from '@/views/Home'
import Contact from '@/views/Contact'
import Cijfer from '@/views/Cijfer'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
    // redirect: '/User/Signin'
  },
  {
    // MyContact/INLINE/nloggers@lsol.nl/37A57C39B1824E9C2D247FAA1FBABCE2
    path: '/MyContact/:uimode?/:uiuser?/:uihash?/:selectedtab?',
    name: 'MyContact',
    public: true,
    component: Contact
  },
  {
    // MyCijfer/INLINE/nloggers@lsol.nl/37A57C39B1824E9C2D247FAA1FBABCE2
    path: '/MyCijfer/:uimode?/:uiuser?/:uihash?',
    name: 'MyCijfers',
    public: true,
    component: Cijfer
  },
  {
    path: '/Contact/:ContactID/:uimode?/:uiuser?/:uihash?',
    name: 'Contact',
    public: true,
    component: Contact
  },
  {
    path: '/Contacts',
    name: 'Contacts',
    public: true,
    component: () => import(/* webpackChunkName: "Contacts" */ '@/views/Contacts')
  },
  {
    path: '/Admin/:uimode?/:uiuser?/:uihash?',
    name: 'Admin',
    rights: 'admin',
    component: () => import(/* webpackChunkName: "Admin" */ '@/views/Admin')
  },
  {
    path: '/User/ChangePass',
    name: 'ChangePass',
    public: true,
    component: () => import(/* webpackChunkName: "User" */ '@/views/User/ChangePass')
  },
  {
    path: '/User/Signin',
    name: 'Signin',
    public: true,
    component: () => import(/* webpackChunkName: "User" */ '@/views/User/Signin')
  },
  {
    path: '/User/Signout',
    name: 'Signout',
    component: () => import(/* webpackChunkName: "User" */ '@/views/User/Signout')
  },
  {
    path: '/User/:uimode?/:uiuser?/:uihash?',
    name: 'User',
    component: () => import(/* webpackChunkName: "User" */ '@/views/User/User')
  },
  // otherwise redirect to Home
  { path: '*', redirect: '/' }
]

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  // check uimode
  // console.log('router.beforeEach, to.params.uimode:', to.params.uimode)
  if (to.params.uimode === undefined || to.params.uimode.toLowerCase() !== 'inline') {
    // store.commit('general/setUimode', true)
  } else {
    store.commit('general/setUimode', false)
    await getTokenByHash(to)
  }

  // redirect to login page if not logged in and trying to access a restricted page
  const route = routes.find(obj => { return obj.name === to.name })
  const authRequired = route.public !== true
  const user = JSON.parse(localStorage.getItem('user'))

  if (authRequired && !user) {
    console.log('authRequired but not logged in')
    store.commit('general/setErrorMessage', { type: 'warning', header: 'Login vereist', message: 'U moet ingelogd zijn om deze pagina te bezoeken.\nU wordt doorgestuurd naar de login pagina.' })
    return next('/User/Signin')
  }

  if (route.rights !== undefined && route.rights !== '') {
    if (user && user.rights.includes(route.rights)) {
      return next()
    } else {
      console.log('testUserRight returned false for group', route.rights)
      store.commit('general/setErrorMessage', { type: 'warning', header: 'Geen rechten', message: 'U heeft geen rechten om deze pagina te bezoeken\n\nU wordt doorgestuurd naar de home pagina.' })
      return next('/')
    }
  }

  next()
})

function getTokenByHash (to) {
  return usersService.getTokenByHash(to.params.uiuser, to.params.uihash).then((response) => {
    store.commit('authentication/setUser', response.data)
  }).catch(() => {
    store.commit('general/setErrorMessage', { type: 'error', header: 'Ongeldige login', message: 'Geen geldige credentials opgegeven.' })
    store.commit('authentication/setUser', undefined)
    localStorage.removeItem('user')
  })
}

export default router
