import axios from 'axios'
import store from '../store'
import { authHeader } from '../_helpers'

const RESOURCE_NAME = '/student'
axios.defaults.baseURL = store.state.general.webServiceAddress
// axios.defaults.headers['Content-Type'] = 'application/json'

export const studentService = {
  getMyCijfers
}

function getMyCijfers () {
  return axios.get(RESOURCE_NAME, { headers: authHeader() }).catch((error) => {
    store.dispatch('general/raiseError', error)
    return Promise.reject(error)
  })
}
