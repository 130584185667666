import formatBool from './formatBool'
import formatCijfer from './formatCijfer'
import formatCijferToLetter from './formatCijferToLetter'
import formatDate from './formatDate'
import formatDateTime from './formatDateTime'
import formatDateWithDay from './formatDateWithDay'
import formatDecimal from './formatDecimal'
import formatEuro from './formatEuro'
import formatTime from './formatTime'
import formatTimeFromDatetime from './formatTimeFromDatetime'
import stripTrailingZeros from './stripTrailingZeros'

export default {
  install (Vue) {
    Vue.filter('formatBool', formatBool)
    Vue.filter('formatCijfer', formatCijfer)
    Vue.filter('formatCijferToLetter', formatCijferToLetter)
    Vue.filter('formatDate', formatDate)
    Vue.filter('formatDateTime', formatDateTime)
    Vue.filter('formatDateWithDay', formatDateWithDay)
    Vue.filter('formatDecimal', formatDecimal)
    Vue.filter('formatEuro', formatEuro)
    Vue.filter('formatTime', formatTime)
    Vue.filter('formatTimeFromDatetime', formatTimeFromDatetime)
    Vue.filter('stripTrailingZeros', stripTrailingZeros)
  }
}
