export default function formatDateTime (value) {
  // console.log('formatDateTime filter')
  if (value === undefined || value == null) { return '' }
  let returnVal = ''
  if (value !== null) {
    let date = Date
    date = new Date(value)
    returnVal = date.getDate().toString() + '-' + (date.getMonth() + 1).toString() + '-' + date.getFullYear().toString() + ' ' + date.getHours() + ':' + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
  }
  return returnVal
}
