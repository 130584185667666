<template>
  <v-container fluid>
    <template v-if="$store.state.contact.cijfers === undefined">
      <v-progress-circular
        indeterminate
        color="grey"
      ></v-progress-circular>
      Gegevens worden geladen...
    </template>
    <template v-else>
      <v-select
        v-if="schoolFilterItems.length > 2"
        :items="schoolFilterItems"
        v-model="schoolFilter"
        label="Filter school"
        @change="richtingFilter = defaultRichtingFilter, faseFilter = defaultFaseFilter, jaarFilter = defaultJaarFilter"
      ></v-select>
      <v-select
        v-if="(schoolFilter != defaultSchoolFilter || schoolFilterItems.length === 2) && richtingFilterItems.length > 2"
        :items="richtingFilterItems"
        v-model="richtingFilter"
        label="Filter opleiding"
        @change="faseFilter = defaultFaseFilter, jaarFilter = defaultJaarFilter"
      ></v-select>
      <v-select
        v-if="(richtingFilter != defaultRichtingFilter || richtingFilterItems.length === 2) && faseFilterItems.length > 2"
        :items="faseFilterItems"
        item-text="name"
        item-value="value"
        v-model="faseFilter"
        label="Filter fase"
        @change="jaarFilter = defaultJaarFilter"
      ></v-select>
      <v-select
        v-if="(faseFilter != defaultFaseFilter || faseFilterItems.length === 2) && jaarFilterItems.length > 2"
        :items="jaarFilterItems"
        v-model="jaarFilter"
        label="Filter jaar"
      ></v-select>
      <v-row>
        <v-col>
          {{filteredModuleIDs.length}} vakken,
          {{filteredCijfers.filter(obj => { return (obj.cijferID !== undefined) }).length}} cijfers
        </v-col>
        <v-col cols="auto">
          <v-switch dense v-model="modulesWithCijferOnly" label="Alleen vakken met cijfers" style="margin-top:0px;"></v-switch>
        </v-col>
        <v-col cols="auto" v-if="schoolFilter !== defaultSchoolFilter || richtingFilter !== defaultRichtingFilter || faseFilter !== defaultFaseFilter || jaarFilter !== defaultJaarFilter">
          <v-btn small @click="schoolFilter = defaultSchoolFilter, richtingFilter = defaultRichtingFilter, faseFilter = defaultFaseFilter, jaarFilter = defaultJaarFilter">Reset filters</v-btn>
        </v-col>
      </v-row>
      <div v-for="school in filteredScholen" :key="school">
        <!-- <h2>School: {{school}}</h2> -->
        <div v-for="richting in getRichtingenBySchool(school)" :key="school+richting" class="mt-2">
          <h2 class="primary--text">Opleiding: {{filteredScholen.length == 1 ? '' : school + '/'}}{{richting}}</h2>
          <!-- <strong>Aantal cijfers: </strong>{{richtingen[school + richting].aantalCijfers}} -->
          <strong style="margin-left: 5px;">EC: </strong>{{richtingen[school + richting].behaaldec}}/{{richtingen[school + richting].ec}}
          <template v-if="richtingen[school + richting].aantalCijfers > 0">
            <strong style="margin-left: 5px;">Gem cijfer: </strong><span :style="richtingen[school + richting].gemCijfer > 54 ? '' : 'color:red;'">{{ $options.filters.formatCijfer(richtingen[school + richting].gemCijfer) }}</span><br />
          </template>
          <v-divider />
          <!-- <h2>Opleiding: {{richting}}</h2> -->
          <div v-for="fase in getFasesBySchoolRichting(school, richting)" :key="school+richting+fase" class="mt-2 pa-2" style="border-radius:10px;background: #EEE;">
            <h3 class="primary--text">Fase: {{fase.substring(3)}}</h3>
            <strong>Aantal vakken: </strong>{{getModulesBySchoolRichtingFase(school, richting, fase).length}}
            <!-- <strong style="margin-left: 5px;">Cijfers: </strong>{{fases[school + richting + fase].aantalCijfers}} -->
            <strong style="margin-left: 5px;">EC: </strong>{{fases[school + richting + fase].behaaldec}}/{{fases[school + richting + fase].ec}}
            <template v-if="fases[school + richting + fase].aantalCijfers > 0">
              <strong style="margin-left: 5px;">Gem cijfer: </strong><span :style="fases[school + richting + fase].gemCijfer > 54 ? '' : 'color:red;'">{{ $options.filters.formatCijfer(fases[school + richting + fase].gemCijfer) }}</span><br />
            </template>
            <v-data-table
              :headers="[
                { text: 'Vak', value: 'module', width: '*', align: 'start' },
                { text: 'Cijfer', value: 'gemCijfer', width: 80, align: 'start' },
                { text: 'Weging', value: 'weging', width: 100, align: 'start' },
                { text: 'EC', value: 'ec', width: 70, align: 'start' },
                { text: 'Jaar', value: 'jaar', width: 100, align: 'start' },
                { text: 'Aantal\xa0cijfers', value: 'data-table-expand', width: 50, align: 'start' }
              ]"
              :items="getModulesBySchoolRichtingFase(school, richting, fase)"
              :sort-by="['jaar','module']"
              multi-sort
              :single-expand="true"
              :expanded.sync="expandedCijferRows[school+richting+fase]"
              item-key="moduleID"
              show-expand
              dense
              mobile-breakpoint="100"
              hide-default-footer
              disable-filtering
              disable-pagination
            >
              <template v-slot:[`item.weging`]="{ item }">
                <span :style="item.weging < 100 && !item.vrijstelling ? 'color:red;' : ''">{{item.weging}}</span>/100
              </template>
              <template v-slot:[`item.gemCijfer`]="{ item }">
                <template v-if="item.vrijstelling">Vrijst.</template>
                <template v-else-if="item.aantalCijfers > 0"><span :style="item.gemCijfer > 54 ? '' : 'color:red;'">{{item.isCijfer ? $options.filters.formatCijfer(item.gemCijfer) : $options.filters.formatCijferToLetter(item.gemCijfer)}}</span></template>
              </template>
              <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
                {{item.aantalCijfers}}
                <v-btn @click="expand(true)" v-if="item.aantalCijfers !== 0 && !isExpanded" icon><v-icon large>expand_more</v-icon></v-btn>
                <v-btn @click="expand(false)" v-if="item.aantalCijfers !== 0 && isExpanded" icon><v-icon large color="primary">expand_less</v-icon></v-btn>
              </template>
              <template v-slot:[`item.ec`]="{ item }">
                <span :style="item.behaaldec !== item.ec && !item.vrijstelling ? 'color:red;' : ''">{{item.behaaldec}}</span>/{{item.ec}}
              </template>
              <template v-slot:expanded-item="{ item }">
                <!-- <td :colspan="1" class="primary"></td> -->
                <td :colspan="3" class="primary" style="padding:5px 0 5px 0!important;">
                  <v-simple-table dense fixed-header>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left primary--text primary" width="*"></th>
                          <th class="text-left primary--text" width="300px">Omschrijving</th>
                          <th class="text-left primary--text text-left" width="100px">Datum</th>
                          <th class="text-left primary--text" width="80px">Cijfer</th>
                          <th class="text-left primary--text" width="100px">Weging</th>
                          <!-- <th class="text-left primary--text" width="80px">Herk</th> -->
                          <!-- <th class="text-left primary--text">Docent</th>
                          <th class="text-left primary--text">Locatie</th>
                            -->
                        </tr>
                      </thead>
                      <tbody>
                        <template>
                          <tr v-for="(cijfer, index) in getCijfersByModuleIDOnlyCijfers(item.moduleID)" :key="'index'+index" class="ma-2 pa-1" style="border: solid 1px">
                            <td class="text-no-wrap primary"></td>
                            <td :class="cijfer.herkansing ? 'red--text font-weight-black' : ''">{{cijfer.comment}}{{cijfer.herkansing ? ' (herkansing)' : ''}}</td>
                            <td class="text-no-wrap text-left">{{ $options.filters.formatDate(cijfer.date) }}</td>
                            <td class="text-no-wrap" :style="cijfer.cijfer > 54 ? '' : 'color:red;'">{{cijfer.isCijfer ? $options.filters.formatCijfer(cijfer.cijfer) : $options.filters.formatCijferToLetter(cijfer.cijfer)}}</td>
                            <td class="text-no-wrap">{{cijfer.weging}}</td>
                            <!-- <td class="text-no-wrap">{{cijfer.herkansing}}</td> -->
                            <!-- <td>{{cijfer.docent}}</td>
                            <td>{{cijfer.locatie}}</td>
                              -->
                          </tr>
                        </template>
                      </tbody>
                    </template>
                  </v-simple-table>
                </td>
                <td :colspan="3" class="primary"></td>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </template>
  </v-container>
</template>

<script>
import { studentService } from '../_services'

export default {
  name: 'Cijfer',
  created () {
    this.getCijfers()
  },
  data () {
    return {
      defaultSchoolFilter: 'Alle scholen...',
      defaultRichtingFilter: 'Alle opleiding...',
      defaultFaseFilter: 'Alle fases...',
      defaultJaarFilter: 'Alle jaren...',
      schoolFilter: 'Alle scholen...',
      richtingFilter: 'Alle opleiding...',
      faseFilter: 'Alle fases...',
      jaarFilter: 'Alle jaren...',
      expanded: {},
      modules: [],
      modulesWithCijferOnly: false,
      richtingen: [],
      fases: [],
      expandedCijferRows: []
    }
  },
  computed: {
    // formTitle () {
    //   return this.editedIndex === -1 ? 'Nieuwe Dienst' : (this.itemDialogDelAction ? 'Verwijder Dienst?' : 'Bewerk Dienst')
    // },
    isAdmin () {
      return (this.$store.state.authentication.user === undefined ? false : (this.$store.state.authentication.user.rights.includes('admin')))
    },
    cijfers () {
      if (this.$store.state.contact.cijfers === undefined) {
        return []
      } else {
        return this.$store.state.contact.cijfers.filter(obj => { return (obj.cijferID !== undefined) })
      }
    },
    moduleIDsWithCijfer () {
      return this.cijfers.map(item => item.moduleID).filter((value, index, self) => self.indexOf(value) === index)
    },
    modulesSchoolFiltered () {
      if (this.modulesWithCijferOnly) {
        return this.modules.filter(obj => { return (this.moduleIDsWithCijfer.includes(obj.moduleID)) }).filter(obj => { return (obj.school === this.schoolFilter || this.schoolFilter === this.defaultSchoolFilter) })
      } else {
        return this.modules.filter(obj => { return (obj.school === this.schoolFilter || this.schoolFilter === this.defaultSchoolFilter) })
      }
    },
    modulesRichtingFiltered () {
      return this.modulesSchoolFiltered.filter(obj => { return (obj.richting === this.richtingFilter || this.richtingFilter === this.defaultRichtingFilter) })
    },
    modulesFaseFiltered () {
      return this.modulesRichtingFiltered.filter(obj => { return (obj.fase === this.faseFilter || this.faseFilter === this.defaultFaseFilter) })
    },
    filteredModules () {
      return this.modulesFaseFiltered.filter(obj => { return (obj.jaar === this.jaarFilter || this.jaarFilter === this.defaultJaarFilter) })
    },
    filteredModuleIDs () {
      return [...new Set(this.filteredModules.map(item => item.moduleID))]
    },
    filteredCijfers () {
      return this.cijfers.filter(obj => { return (this.filteredModuleIDs.includes(obj.moduleID)) })
    },
    filteredScholen () {
      return [...new Set(this.filteredModules.map(item => item.school))]
    },
    schoolFilterItems () {
      if (this.modulesWithCijferOnly) {
        return ([this.defaultSchoolFilter]).concat([...new Set(this.cijfers.map(item => item.school))])
      } else {
        return ([this.defaultSchoolFilter]).concat([...new Set(this.modules.map(item => item.school))])
      }
    },
    richtingFilterItems () {
      return ([this.defaultRichtingFilter]).concat([...new Set(this.modulesSchoolFiltered.map(item => item.richting))])
    },
    faseFilterItems () {
      const origFases = [...new Set(this.modulesRichtingFiltered.map(item => item.fase).sort())]
      const fases = []
      fases.push({ name: this.defaultFaseFilter, value: this.defaultFaseFilter })
      origFases.forEach(function (fase) {
        const newFase = {}
        newFase.name = fase.substring(3)
        newFase.value = fase
        fases.push(newFase)
      })
      // return ([this.defaultFaseFilter]).concat([...new Set(this.modulesRichtingFiltered.map(item => item.fase).sort())])
      return fases
    },
    jaarFilterItems () {
      return ([this.defaultJaarFilter]).concat([...new Set(this.modulesFaseFiltered.map(item => item.jaar))])
    }
  },
  watch: {
  },
  methods: {
    getGemCijferObjectFromCijfers (cijfers, vanaf) {
      const totWeging = cijfers.map(item => item.weging).reduce(function (a, b) { return a + b }, 0)
      let sumGewogenCijfers = 0
      cijfers.forEach(function (cijfer) {
        sumGewogenCijfers = sumGewogenCijfers + (cijfer.cijfer * cijfer.weging)
      })
      const gemCijfer = sumGewogenCijfers / totWeging
      return { gemCijfer: (isNaN(gemCijfer) ? 0 : gemCijfer), totWeging: totWeging, aantalCijfers: cijfers.length }
    },
    getCijfers () {
      if (this.cijfers.length === 0) {
        studentService.getMyCijfers().then((response) => {
          this.$store.commit('contact/setCijfers', response.data)
          const moduleIDs = [...new Set(response.data.map(item => item.moduleID))]
          const vm = this
          moduleIDs.forEach(function (moduleID) {
            const curModule = response.data.find(obj => { return obj.moduleID === moduleID })
            const curModuleCijfers = response.data.filter(obj => { return obj.moduleID === moduleID && obj.cijferID !== undefined })
            // const totWeging = curCijfers.map(item => item.weging).reduce(function (a, b) { return a + b }, 0)
            // let sumGewogenCijfers = 0
            // curCijfers.forEach(function (cijfer) {
            //   sumGewogenCijfers = sumGewogenCijfers + (cijfer.cijfer * cijfer.weging)
            // })
            // const gemCijfer = curCijfers.map(item => item.cijfer).reduce(function (a, b) { return a + b }, 0) / curCijfers.length
            // const gemCijfer = sumGewogenCijfers / totWeging
            // const behaaldec = (totWeging >= 100 && gemCijfer >= 55) ? curModule.ec : 0
            const gemModuleCijferObject = vm.getGemCijferObjectFromCijfers(curModuleCijfers, 'module')
            const behaaldec = (gemModuleCijferObject.totWeging >= 100 && gemModuleCijferObject.gemCijfer >= 55) ? curModule.ec : 0
            vm.modules.push({
              school: curModule.school,
              richting: curModule.richting,
              fase: curModule.fase,
              jaar: curModule.jaar,
              moduleID: curModule.moduleID,
              path: curModule.path,
              module: curModule.module,
              ec: curModule.ec,
              vrijstelling: curModule.vrijstelling,
              weging: gemModuleCijferObject.totWeging,
              isCijfer: curModule.isCijfer,
              aantalCijfers: gemModuleCijferObject.aantalCijfers,
              gemCijfer: gemModuleCijferObject.gemCijfer,
              behaaldec: curModule.vrijstelling ? curModule.ec : behaaldec
            })
          })
          this.richtingen = []
          const scholen = [...new Set(vm.modules.map(item => item.school))]
          scholen.forEach(function (school) {
            const curRichtingen = [...new Set(vm.modules.filter(obj => { return obj.school === school }).map(item => item.richting))]
            curRichtingen.forEach(function (richting) {
              // const curRichtingCijfers = response.data.filter(obj => { return obj.school === school && obj.richting === richting && obj.cijferID !== undefined }).map(item => item.cijfer)
              // const gemCijfer = curCijfers.reduce(function (a, b) { return a + b }, 0) / curCijfers.length
              // const curRichtingCijfers = response.data.filter(obj => { return obj.school === school && obj.richting === richting && obj.cijferID !== undefined }).map(item => ({ cijfer: item.cijfer, weging: item.weging }))
              const curFases = [...new Set(vm.modules.filter(obj => { return obj.school === school && obj.richting === richting }).map(item => item.fase))]
              curFases.forEach(function (fase) {
                // const curCijfers = response.data.filter(obj => { return obj.school === school && obj.richting === richting && obj.fase === fase && obj.cijferID !== undefined }).map(item => item.cijfer)
                // const gemCijfer = curCijfers.reduce(function (a, b) { return a + b }, 0) / curCijfers.length
                // const curFaseCijfers = response.data.filter(obj => { return obj.school === school && obj.richting === richting && obj.fase === fase && obj.cijferID !== undefined }).map(item => item.cijfer)
                const curFaseCijfers = vm.modules.filter(obj => { return obj.school === school && obj.richting === richting && obj.fase === fase }).map(item => ({ cijfer: item.gemCijfer, weging: 100, ec: item.ec, behaaldec: item.behaaldec }))
                // console.log('curFaseCijfers', curFaseCijfers)
                // const curFaseCijfers = response.data.filter(obj => { return obj.school === school && obj.richting === richting && obj.fase === fase && obj.cijferID !== undefined }).map(item => ({ cijfer: item.cijfer, weging: item.weging }))
                const gemFaseCijferObject = vm.getGemCijferObjectFromCijfers(curFaseCijfers, 'fase')
                const faseEc = curFaseCijfers.map(item => item.ec).reduce(function (a, b) { return a + b }, 0)
                const faseBehaaldEc = curFaseCijfers.map(item => item.behaaldec).reduce(function (a, b) { return a + b }, 0)
                vm.fases[school + richting + fase] = { aantalCijfers: gemFaseCijferObject.aantalCijfers, gemCijfer: gemFaseCijferObject.gemCijfer, ec: faseEc, behaaldec: faseBehaaldEc }
              })
              // const curRichtingCijfers = vm.fases.map(item => ({ cijfer: item.gemCijfer, weging: 100 }))
              const curRichtingCijfers = Object.entries(vm.fases).map(([key, value]) => ({ cijfer: value.gemCijfer, weging: 100, ec: value.ec, behaaldec: value.behaaldec }))
              // console.log('vm.fases', vm.fases)
              // console.log('curRichtingCijfers', curRichtingCijfers)
              const gemRichtingCijferObject = vm.getGemCijferObjectFromCijfers(curRichtingCijfers, 'richting')
              const richtingEc = curRichtingCijfers.map(item => item.ec).reduce(function (a, b) { return a + b }, 0)
              const richtingBehaaldEc = curRichtingCijfers.map(item => item.behaaldec).reduce(function (a, b) { return a + b }, 0)
              vm.richtingen[school + richting] = { aantalCijfers: gemRichtingCijferObject.aantalCijfers, gemCijfer: gemRichtingCijferObject.gemCijfer, ec: richtingEc, behaaldec: richtingBehaaldEc }
            })
          })
        })
      }
    },
    getRichtingenBySchool (school) {
      return [...new Set(this.filteredModules.filter(obj => { return obj.school === school }).map(item => item.richting))]
    },
    getFasesBySchoolRichting (school, richting) {
      return [...new Set(this.filteredModules.filter(obj => { return obj.school === school && obj.richting === richting }).map(item => item.fase))]
    },
    getJarenBySchoolRichtingFase (school, richting, fase) {
      return [...new Set(this.filteredModules.filter(obj => { return obj.school === school && obj.richting === richting && obj.fase === fase }).map(item => item.jaar))]
    },
    getModulesBySchoolRichtingFase (school, richting, fase) {
      return [...new Set(this.filteredModules.filter(obj => { return obj.school === school && obj.richting === richting && obj.fase === fase }))]
    },
    getModulesBySchoolRichtingFaseJaar (school, richting, fase, jaar) {
      return [...new Set(this.filteredModules.filter(obj => { return obj.school === school && obj.richting === richting && obj.fase === fase && obj.jaar === jaar }))]
    },
    getModuleIDsBySchoolRichtingFaseJaar (school, richting, fase, jaar) {
      return [...new Set(this.getModulesBySchoolRichtingFaseJaar(school, richting, fase, jaar).map(item => item.moduleID))]
    },
    getModuleByID (moduleID) {
      return this.modules.find(obj => { return obj.moduleID === moduleID })
    },
    getCijfersByModuleID (moduleID) {
      return [...new Set(this.filteredCijfers.filter(obj => { return obj.moduleID === moduleID }))]
    },
    getCijfersByModuleIDOnlyCijfers (moduleID) {
      return [...new Set(this.getCijfersByModuleID(moduleID).filter(obj => { return (obj.cijferID !== undefined) }))]
    }
  }
}
</script>

<style scoped>
.v-data-table:not(.theme--dark) tr:nth-child(even) {
  background-color: rgba(86, 0, 123, 0.1)!important;
}
</style>
