import axios from 'axios'
import store from '../store'
import { authHeader } from '../_helpers'

const RESOURCE_NAME = '/Global'
axios.defaults.baseURL = store.state.general.webServiceAddress
axios.defaults.headers['Content-Type'] = 'application/json'

export const globalService = {
  getContactFields,
  getContactTabFields,
  getAddressFields,
  getCommunicationFields,
  getContactTabs,
  getAllSettings,
  sendmail
}

function getContactFields () {
  return axios.get(`${RESOURCE_NAME}/FieldSettings/Contact`, { headers: authHeader() }).catch((error) => {
    store.dispatch('general/raiseError', error)
    return Promise.reject(error)
  })
}

function getContactTabFields (tabid) {
  return axios.get(`${RESOURCE_NAME}/FieldSettings/ContactTab/${tabid}`, { headers: authHeader() }).catch((error) => {
    store.dispatch('general/raiseError', error)
    return Promise.reject(error)
  })
}

function getAddressFields () {
  return axios.get(`${RESOURCE_NAME}/FieldSettings/Address`, { headers: authHeader() }).catch((error) => {
    store.dispatch('general/raiseError', error)
    return Promise.reject(error)
  })
}

function getCommunicationFields () {
  return axios.get(`${RESOURCE_NAME}/FieldSettings/Communication`, { headers: authHeader() }).catch((error) => {
    store.dispatch('general/raiseError', error)
    return Promise.reject(error)
  })
}

function getContactTabs () {
  return axios.get(`${RESOURCE_NAME}/ContactTabs`, { headers: authHeader() }).catch((error) => {
    store.dispatch('general/raiseError', error)
    return Promise.reject(error)
  })
}

function getAllSettings () {
  return axios.get(`${RESOURCE_NAME}/Settings`, { headers: authHeader() }).catch((error) => {
    store.dispatch('general/raiseError', error)
    return Promise.reject(error)
  })
}

function sendmail (to, cc, subject, body) {
  return axios.post(`${RESOURCE_NAME}/Sendmail`, { to: to, cc: cc, subject: subject, body: body }, { headers: authHeader() }).catch((error) => {
    store.dispatch('general/raiseError', error)
    return Promise.reject(error)
  })
}
