export default function formatEuro (value) {
  if (value === undefined || value == null || value === 0) { return '' }
  const decimalCount = 2
  const decimal = ','
  const thousands = '.'
  const negativeSign = value < 0 ? '-' : ''

  const i = parseInt(value = Math.abs(Number(value) || 0).toFixed(decimalCount)).toString()
  const j = (i.length > 3) ? i.length % 3 : 0
  let stringVal = '€ ' + negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands)
  if (Math.abs(value - i) > 0) {
    stringVal = stringVal + (decimalCount ? decimal + Math.abs(value - i).toFixed(decimalCount).slice(2) : '')
  }
  return stringVal
  // var stringVal = value.toString()
  // return stringVal
  // if (stringVal.endsWith('.00')) {
  //   return 'ja'
  // } else {
  //   return 'nee'
  // }
}
