<template>
  <div><template v-for="(line, index) in (inputText !== undefined ? (typeof inputText === 'string' ? inputText.split('\n') : inputText.toString()) : '')">{{line.replace(/[\r]/g, '')}}<br :key="'br'+index" v-if="index < (inputText.toString().split('\n').length - 1)"></template></div>
</template>

<script>
export default {
  props: {
    inputText: String
  }
}
</script>
