import { router } from '@/_helpers'

export const general = {
  namespaced: true,
  state: {
    // webServiceAddress: 'https://localhost:44349/API',
    // webServiceAddress: 'https://lsadmin.lsol.nl/API',
    webServiceAddress: 'https://webportal.evangelisch-college.nl/API',
    adminEmail: 'Noah Admin <nloggers@lsol.nl>',
    errorMessage: null,
    settings: [],
    uimode: true
  },
  mutations: {
    setErrorMessage (state, input) {
      state.errorMessage = input
      if (input !== null && input.manual !== true) {
        state.setTimeoutObject = setTimeout(() => {
          state.errorMessage = null
        }, 7000)
      }
    },
    setSettings (state, input) {
      state.settings = input
    },
    setUimode (state, input) {
      state.uimode = input
      // console.log('setUimode to:', input)
    }
  },
  actions: {
    raiseError ({ commit }, error) {
      // console.log('raiseError')
      // console.log('error', JSON.stringify(error))
      // console.log('error.config', error.config)
      if (error.response) {
        // console.log('type 1')
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response) {
          console.log('error.response.data', error.response.data)
          console.log('error.response.status', error.response.status)
          console.log('error.response.headers', error.response.headers)
          console.log('JSON.stringify(error.response.headers)', JSON.stringify(error.response.headers))
        }
        if (error.response.status === 401) {
          console.log('is 401 error')
          router.push({ path: '/User/Signin' })
          commit('setErrorMessage', { type: 'warning', header: 'Status ' + error.response.status + ' error', message: error.response.data })
        } else {
          commit('setErrorMessage', { type: 'error', header: 'Status ' + error.response.status + ' error', message: error.response.data })
        }
      } else if (error.request) {
        console.log('type 2')
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request)
      } else {
        console.log('type 3')
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message)
      }
    }
  }
}
